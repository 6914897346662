import React from "react";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Accordion from "@ui/accordion";
import { AccordionRowWrap, IntroWrapper } from "./style";
const IntroAreaReverse = ({ data, index, layout, ...props }) => {
  if (!data?.items || data.items.length === 0) {
    return null;
  }
  const midpoint = Math.ceil(data.items.length / 2);
  const colFirstData = data.items.slice(0, midpoint);
  const colSecondData = data.items.slice(midpoint);
  return (
    <IntroWrapper layout={layout} {...props} id={data.section}>
      <Container>
        <Row>
          <Col lg={12}>
            <SectionTitle
              subtitle={data.section_title?.subtitle}
              title={data.section_title?.title}
              description={data.section_title?.description}
            />
          </Col>
        </Row>
        {/* Accordion */}
        {
          data?.items &&
          <AccordionRowWrap>
            <Accordion className="colFirst accordionWrapper" data={colFirstData} layout={4} />
            <Accordion className="colSecond accordionWrapper" data={colSecondData} layout={4} />
          </AccordionRowWrap>
        }
      </Container>
    </IntroWrapper>
  );
};
export default IntroAreaReverse;
