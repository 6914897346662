import styled, { device } from "@styled";
export const IntroWrapper = styled.section`
    padding: 40px 0;
    background-image: linear-gradient(-33deg, #15054f 0%, #561783 99%, #561783 100%);
    ${device.medium} {
      padding: 60px 0;
    }
    ${device.large} {
      padding: 100px 0 ;
    }
    .section-title {
      margin-bottom:30px;
      ${device.large} {
        margin-bottom:45px;
      }
      p {
        color: #fff;
        font-size: 15px;
        ${device.xlarge} {
          font-size: 16px;
        }
        ${device.xxlarge} {
          font-size: 17px;
        }
      }
    }
`;

export const AccordionRowWrap = styled.div`
  display:grid;
  grid-gap:15px;
  ${device.medium} {
    grid-template-columns:repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-gap:20px;
  }
  .accordionWrapper{
    .accordion{
      .accordion-item{
        background-color: rgba(255,255,255,0.10980392156862745);
        box-shadow:none;
        border: 1px solid rgba(210,210,210,0.55) !important;  
        margin:0;
        &:not(:last-child){
          margin-bottom:15px;
          ${device.xlarge} {
            margin-bottom:20px;
          }
        }
        .accordion-header{
          .accordion-button {
            font-size: 15px;
            letter-spacing: 1px;
            line-height: 1.1;
            font-weight: 700;
            padding: 15px 25px 15px 15px;
            ${device.medium} {
              padding: 16px 30px 16px 20px;
            }
            ${device.xlarge} {
              font-size: 16px;
              padding: 18px 36px 18px 25px;
            }
            ${device.xxlarge} {
              font-size: 17px;
            }
            &::before{
              font-size: 13px;
              right: 10px;
              ${device.xlarge} {
                font-size: 15px;
                right: 15px;
              }
              ${device.xlarge} {
                font-size: 14px;
                right: 20px;
              }
            }
          }
        }
        .accordion-body {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 1.5;
          padding: 15px 25px 20px 15px;
          p{
            margin:0;
            & + *{
              margin:12px 0 0;
              ${device.large} {
                margin:16px 0 0;
              }
            }
          }
          *:empty {
              display: none;
          }
          ${device.xlarge} {
            font-size: 16px;
            padding: 24px 46px 24px 24px;
          }
        }
      }
    }
  }
`;